.app {
    min-height: 100vh;
    display: flex;
}

.wrapper {
    width: 100%;
    padding: 2.5%;
}

.buttonLogout {
    margin-bottom: 20px;
}
