.wrapper {
    height: 64px;
    box-shadow: 0px 2px 8px #f0f1f2;
    background-color: #ffffff;
    padding: 0 40px;
    min-width: 1080px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;
}

.leftSide {
    float: left;
    display: flex;
    align-items: center;
}

.logo {
    cursor: pointer;
}

.title {
    font-weight: 500;
    font-size: 14px;
    color: #a6192e;
    margin: 2px 0 0 13px;
    letter-spacing: 0.04em;
}

.rightSide {
    float: right;
    display: flex;
    align-items: center;
}

.siteLogo {
    display: flex;
    width: 150px;
    height: 30px;
    margin-right: 20px;
    overflow: hidden;

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.logout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    width: 93px;
    padding-left: 8px;

    .logoutInside {
        display: flex;
    }
}

.userData {
    display: flex;
    align-items: center;
}

.nameContainer {
    display: flex;
    align-items: center;
    margin-right: 28px;
}

.nameInitial {
    display: flex;
    width: 24px;
    height: 24px;
    background: #a6192e;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #ffffff;
    font-size: 16px;
    margin-right: 8px;
}

.fullname {
    font-weight: 500;
    color: #1a3d6e;
}
