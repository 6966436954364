.dateTimePicker {
    padding-left: 0;
    width: 100%;
    input {
        font-family: Montserrat, sans-serif;
    }
}

.actionsBlock {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
    min-height: 40px;
}

.actionButtons {
    display: flex;
    gap: 10px;
    margin-left: auto;
}
