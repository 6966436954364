.wrapper {
    padding: 24px 120px 0;
}

.table {
    & :global(.ant-table-row) {
        cursor: pointer;
        height: 57px;
    }
}
