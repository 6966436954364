.table {
    height: 100vh;
    background-color: white;
    width: 100%;
}

.printedLayoutHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main {
    vertical-align: top;
}
