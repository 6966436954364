@use './colors';
@use 'sass:meta';

body {
    margin: 0;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: colors.$aqua;
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
}

h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
}

h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

:root {
    @each $name, $value in meta.module-variables('colors') {
        --#{$name}: #{$value};
    }
}
