@import 'src/styles/colors';

.wrapper {
    min-width: 900px;
}

.actionLink {
    color: $blue;
}

.actionSpace {
    cursor: pointer;
}

.roleList {
    padding-left: 14px;
}
