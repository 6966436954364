.form {
    :global .ant-form-item-label {
        font-weight: bold;
    }

    :global .ant-form-item-row {
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        gap: 0px;
    }

    :global .ant-form-item-label {
        text-align: start;
        white-space: normal;

        label {
            height: auto;

            &:after {
                display: none;
            }
        }
    }

    :global .ant-form-item-control {
        flex-grow: 0;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.field {
    width: 100%;
    margin-bottom: 0;
    display: flex;

    &._hidden {
        display: none;
    }
}

.footer {
    margin-top: 24px;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
