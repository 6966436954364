@import '../../styles/colors.scss';

.wrapper {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 24px;
}

.name {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

.boxsetMap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

.samplesRange {
    display: flex;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
}

.sampleNumber {
    font-weight: 700;
}

.box {
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid $pale-grey;
    background-color: #f9fbfc;
}

.boxTitle {
    font-weight: 600;
    margin: 10px;
}

.samplesWrapper {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    border: 1px solid $pale-grey;
}

.screwCapWrapper {
    grid-auto-flow: column;
    grid-template-rows: repeat(14, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 0 10px;
}

.samplesGroup {
    display: flex;
}
