@import '../../styles/colors.scss';

.stepper {
    & :global(.ant-steps-item-description) {
        max-width: unset !important;
    }

    & :global(.ant-steps-item-finish .ant-steps-item-icon) {
        border-color: $blue;
        background-color: transparent;
    }

    & :global(.ant-steps-item-wait .ant-steps-item-icon) {
        border-color: $grey;
        background-color: transparent;

        & :global(.ant-steps-icon) {
            color: $grey;
        }
    }

    overflow: hidden;
}
