.printTable {
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-content: baseline;
    margin: 10px 0;
    width: 100%;
}
