@import '../../styles/colors.scss';

.activeBreadcrumb {
    color: $black;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5%;
    margin-bottom: 16px;
}

.divider {
    margin: 20px 0;
}

.breadcrumb {
    margin-bottom: 40px;
}

.pageTitle {
    margin: 0;
}
