// BLACK
$black: #000000;

// BLUE:
$blue: #0783c1;
$pale-blue: #e6f7ff;
$navy-blue: #002766;
$light-blue: #bae7ff;

// GREEN:
$teal: #006d75;
$teal-green: #36cfc9;
$light-teal: #28cdaf;

// WHITE:
$white: #ffffff;

// GRAY:
$grey: #bfbfbf;
$pale-grey: #d9d9d9;
$dark-grey: #6d6e72;
$smoke-gray: #595959;
$charcoal: #434343;

// RED:
$red: #f5222d;
$dust-red: #fff1f0;
$deep-pink: #c41d7f;

// PEACH:
$peach: #ffa39e;
$coral: #ff9c6e;
$light-peach: #ffc53d;
$pale-peach: #ffe1d6;

// ORANGE:
$orange: #faad14;

// VIOLET:
$violet: #9254de;

// AQUA:
$aqua: #eff5f9;

// RED
$dark-pink: #ba122b;
