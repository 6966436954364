@import '../../styles/colors.scss';

.cell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: $white;
}

.smallCell {
    font-size: 12px;
    height: 28px;
    width: 28px;
    & .specimen {
        width: 24px;
        height: 24px;
    }
}

.cellFrozen {
    background-color: $dust-red;
}

.cellProcessed {
    background-color: darken($color: $dust-red, $amount: 3);
}

.specimen {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: $white;
    border: 1px solid $pale-grey;
    color: $grey;
    font-weight: 500;
}

.specimenWithAliquot {
    background-color: $peach;
    border: 1px solid $peach;
    color: $white;
}

.specimenFrozen {
    border: 1px solid $peach;
    color: $navy-blue;
}

.specimenEmpty {
    background: $pale-blue;
    border: 1px solid $light-blue;
    color: $navy-blue;
}
