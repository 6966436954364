.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filtersGroup {
    display: flex;
    gap: 20px;
    align-items: center;
}

.filerItem {
    display: flex;
    gap: 10px;
    align-items: center;
}
