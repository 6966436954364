.boxsetInfo {
    display: grid;
    grid-template-columns: 15% 21% 21% 26% auto;
}

.card {
    position: relative;
    & :global(.ant-card-body) {
        padding: 16px 24px;
    }
}

.cardLoading {
    opacity: 0.5;
    &::after {
        position: absolute;
        content: '';
        background-color: #ffffff70;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.infoItemWrapper {
    flex: 1 1 auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;

    &:first-child {
        align-items: flex-start;
    }

    &:last-child {
        align-items: flex-end;
    }
}

.infoItemWrapper + .infoItemWrapper {
    border-left: solid 1px rgba(0, 0, 0, 0.06);
}

.infoItemTitle {
    font-weight: 700;
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 22px;
}
