@import 'src/styles/colors';

.modal {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 24px;
}

.modalHeader {
    display: flex;
    gap: 16px;
}

.modalIcon {
    font-size: 24px;
    color: $orange;
    align-self: baseline;
}

.modalTitle {
    font-weight: 700;
    font-size: 16px;
}

.modalSubTitle {
    margin-left: 40px;
}

.actionsCell {
    margin: 0 -8px;
}
