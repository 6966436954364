@import '../../../styles/colors.scss';

.actionsBlock {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.actionButtons {
    display: flex;
    gap: 10px;
}

td {
    &.flagCell {
        &:global(.ant-table-cell) {
            padding: 0 !important;
        }
    }
}

.table {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 48px;

    & :global(.ant-table-thead .ant-table-cell) {
        height: 86px;
        padding-left: 17px !important;
    }

    & :global(.ant-table-cell) {
        padding: 0 16px !important;
    }

    & :global(.ant-table-pagination) {
        margin-top: 24px !important;
    }
}

.nameColumnWrapper,
.collectedColumnWrapper,
.specimenColumnWrapper {
    width: 2000px;
    max-width: 200px;
    overflow: hidden;
}

.nameColumn,
.locationColumn,
.specimenColumn {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

@media print {
    .nameColumnWrapper,
    .locationColumnWrapper,
    .specimenColumnWrapper {
        max-width: 140px;
    }
}

.searchInput {
    width: auto;
}

.errored {
    & .flagCell {
        background-color: $dust-red;
    }
}
