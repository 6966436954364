.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;

    background-color: #1a3d6e;
    background-image: url(../../../public/bg-pattern.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto 100%;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    // background-color: #f5f5f5;
}

.card {
    & :global(.ant-card-body) {
        display: flex;
        flex-direction: column;
        &::before {
            display: none;
        }
    }
    width: 400px;
}

.logo {
    width: 80%;
    align-self: center;
    margin-bottom: 40px;
}

.ssoButtons {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
