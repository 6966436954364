.baseWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contentWrapper {
    padding: 20px 120px 0;
    // min-width: 900px;
}
