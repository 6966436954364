@import '../../styles/colors.scss';

.tableSkeleton {
    background: linear-gradient(270deg, $pale-grey, $teal-green);
    background-size: 200% 100%;
    height: 22px;
    border-radius: 6px;
    animation: skeletonAnimation 1.5s ease infinite;
    opacity: 0.6;
}

@keyframes skeletonAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
